<template>
<div>
  <div class="container-fluid">
    <PageTitle />

    <div 
      v-if="isList" 
      class="card"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-md-8">
            <h5 class="card-title">{{pageTitle}} List {{row.al_title}}</h5>
          </div>
          <div class="col-md-4">
            <form @submit.prevent="doFilter(),doSearch()">
              <div class="form-group mb-0">
                <div class="input-group">
                  <input v-model="filter.search" @input="checkDisabled" type="text" class="form-control"
                    placeholder="Type and Press Enter" />
                  <div class="input-group-append">
                    <button disabled id="btn-search" class="btn btn-info" type="submit"><i class="fas fa-search"></i></button>
                  </div>
                  <button v-if="isSearch" v-tooltip="'Clear Search Results'" @click="doReset(),clsSearch()" type="button" class="btn btn-danger ml-1"><i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="table-responsive">
          <table class="table product-overview table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Username</th>
                <th>Full Name</th>
                <th>Level Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
              <tr v-for="(v,k) in data.data" :key="k">
                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                <td><span v-tooltip="v.bu_updated_date ? 'Last Updated '+ v.bu_updated_date.dates('format2') : 'Created '+ v.bu_created_date.dates('format2')">{{ v.bu_username }}</span></td>
                <td>{{ v.bu_full_name }}</td>
                <td>{{ v.bul_name }}</td>
                <td><LabelStatus :status="v[statusKey]" /></td>
                <td>
                  <router-link 
                    :to="{name:$route.name, params:{pageSlug:v[idKey]}}"  
                    class="text-inverse icon_action act_icon" v-tooltip="'Edit'">
                    <i class="ti-marker-alt"></i>
                  </router-link> 
                  <a 
                    href="javascript:;" 
                    class="text-inverse icon_action act_icon" v-tooltip="'Change Status'"
                    @click="doChangeStatusUser(k,v)">
                    <i class="ti-settings"></i>
                  </a> 
                  <a 
                    href="javascript:;" 
                    class="text-inverse icon_action act_icon" 
                    v-tooltip="'Delete'"
                    @click="doDeleteUser(k,v)">
                    <i class="ti-trash"></i>
                  </a>
                </td>
              </tr>
              <tr v-if="notFound"><td colspan="99" class="text-center"><h4>{{notFound}}</h4></td></tr>
            </tbody>
          </table>
      </div>
      <div class="card-footer" v-if="data.data!==false&&data.data.length">
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <div class="pagination-flat float-right">
              <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                <span slot="next-nav"><i class="icon-arrow-right"></i></span>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>


    
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Add':'Edit'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doPost)">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Full Name 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.bu_full_name"
                        type="text" 
                        class="form-control" 
                        placeholder="Full Name" 
                      /> 
                      <VValidate name="Full Name" v-model="row.bu_full_name" :rules="{required : 1, min : 3, 
                      regex:/^[a-zA-Z]+(?: [a-zA-Z]+)*$/gm }"  />
                    </div>
                    <div class="form-group">
                      <label class="control-label">
                        Mobile Number
                      </label>
                      <input 
                        v-model="row.bu_phone"
                        type="text" 
                        class="form-control" 
                        placeholder="Mobile Number" 
                      /> 
                      <VValidate name="Mobile Number" 
                      v-model="row.bu_phone" :rules="{ regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/ }" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Email Address 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.bu_email_address"
                        type="email" 
                        class="form-control" 
                        placeholder="Email Address" 
                      /> 
                      <VValidate name="Email Address" v-model="row.bu_email_address" :rules="mrValidation.bu_email_address" />
                    </div>
                    <div class="form-group">
                      <label class="control-label">
                        Username 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.bu_username"
                        type="text" 
                        class="form-control" 
                        placeholder="Username" 
                      /> 
                      <VValidate name="Username" v-model="row.bu_username" :rules="{required: 1, regex: /^(?=.{4,20}$)(?:[a-zA-Z\d]+(?:(?:\.|-|_)[a-zA-Z\d])*)+$/}" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Password 
                        <span v-if="!row.bu_id" class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.bu_password"
                        type="password" 
                        class="form-control" 
                      /> 
                      <VValidate name="Password" v-model="row.bu_password" :rules="{required:!row.bu_id, min:5}" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Confirm Password 
                        <span v-if="!row.bu_id" class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.bu_password2"
                        type="password" 
                        class="form-control" 
                      /> 
                      <VValidate name="Confirm Password" v-model="row.bu_password2" :rules="row.bu_password === '' || row.bu_password === undefined ? row.bu_password2 === '' || row.bu_password2 === undefined && isAdd ? 'required' : 'confirmed:Password|min: 5' : 'required|confirmed:Password|min: 5'" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Level Name 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <div @click="menuHeight">
                        <vSelect
                          v-model="row.bu_level" 
                          placeholder="Select Level Name"
                          :options="mrLevel" 
                          :reduce="v=>v.bul_id" :label="'bul_name'"
                        ></vSelect>
                      </div>
                      <VValidate name="Level Name" v-model="row.bu_level" :rules="mrValidation.bu_level" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Status 
                        <span class="text-danger mr5">*</span> 
                      </label>
                      <div>
                        <InputRadio v-model="row[statusKey]" name="status" option="Y" />
                        <InputRadio v-model="row[statusKey]" name="status" option="N" />
                      </div>
                      <VValidate name="Status" v-model="row[statusKey]" :rules="mrValidation[statusKey]" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label">
                    Profile Photo
                  </label>
                  <Uploader v-model="row.bu_avatar" type="user" label="Photo"/>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                  <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
let $ = global.jQuery

export default{
  extends:GlobalVue,
  components:{PageTitle},
  data(){
    return {
      idKey:'bu_id',
      statusKey:'bu_is_active',
      mrLevel:[],
      isSearch : false
    }
  },
  methods: {
    doSearch (){
      this.isSearch = true
      $('#btn-search').attr("disabled", "disabled")
    },
    clsSearch(){
      this.isSearch = false
      setTimeout(() => {
        $('#btn-search').attr("disabled", "disabled")
      }, 0)
    },
    doReset(){
      this.filter={}
      this.$router.push({name:this.$route.name,query:{page:1}});
      setTimeout(() => {
        $('#btn-search').attr("disabled", "disabled")
      }, 0)
    },
    checkDisabled() {
      if (this.filter.search.length <= 0) {
        $('#btn-search').attr("disabled", "disabled")
      } else {
        $('#btn-search').removeAttr("disabled")
      }
    },
    doPost(){
      if(this.isAdd){
        this.row["bu_created_by"] = this.user.id
        this.doSubmitCRUD();
      }
      else{
        this.row["bu_updated_by"] = this.user.id
        this.doSubmitCRUD();
      }
    },
    menuHeight(){
      $(".vs__dropdown-menu").css({"max-height": "90px"})
    },
    doDeleteUser(k,v){
      if(v[this.idKey] == this.user.id){
        global.Swal.fire({
          title: 'This Account is Being Used',
          icon: 'warning',
        })
      }
      else{
        this.doDelete(k,v)
      }
    },
    doChangeStatusUser(k,v){
      if(v[this.idKey] == this.user.id){
        global.Swal.fire({
          title: 'This Account is Being Used',
          icon: 'warning',
        })
      }
      else{
        this.doChangeStatus(k,v)
      }
    }
  },
  mounted(){
    this.apiGet()
    setTimeout(() => {
      if(this.isList){
        if(this.$route.query.search){        
          if (this.filter && this.filter.search.length <= 0) {
            this.isSearch = false
            $('#btn-search').attr("disabled", "disabled")
          } else {
            this.isSearch = true
            $('#btn-search').removeAttr("disabled")
          }  
        }
      }
    },300)

    setTimeout(() => {
      if(!this.isAdd) this.row.bu_level = +this.row.bu_level
    }, 500)
  },
  watch:{
    $route(){
      this.apiGet()
      setTimeout(() => {
        if(!this.isAdd) this.row.bu_level = +this.row.bu_level
      }, 500)
    }
  }
}
</script>